/* eslint-disable react/no-danger */
import React, { useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import get from 'lodash/get';
import keysIn from 'lodash/keysIn';
import { useTranslation } from 'react-i18next';

import { TLanguageKey, TRegionKey } from '@source/interface';
import { getAlterLinks, getRegionLanguageInRouter, getUrlNoQuery } from '@source/utils/utils';
import { MetaItemProps } from '@source/pages/Home/components/HeadMeta/HeadMetaProps';
import META_DATA from '@source/constants/metadata';
import { AVAILABLE_LANGUAGES, SUPPORTED_COUNTRIES } from '@source/constants/config';
import { NEXT_PUBLIC_WEB_CR } from '@source/constants/env';
import { isProduction } from '@source/constants/common';

interface HeadMetaProps {
  metaKey: string;
  region: TRegionKey;
  // eslint-disable-next-line react/require-default-props
  metaData?: MetaItemProps;
}

const HeadMeta = ({ metaKey, region, metaData }: HeadMetaProps) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const { asPath } = useRouter();
  const regionLanguage = getRegionLanguageInRouter(router, region);
  const currentLang = region === 'sg' && i18n.language === 'ms' ? 'ms-SG' : regionLanguage?.language || i18n.language;
  const url = `${NEXT_PUBLIC_WEB_CR}/${regionLanguage.region}${asPath}`;
  const canonicalUrl = getUrlNoQuery(url);
  let meta = get(META_DATA, `${region || 'sg'}.${metaKey || 'index'}`);

  const isIncludingLang = keysIn(meta).every((m) => AVAILABLE_LANGUAGES.includes(m as TLanguageKey));

  if (isIncludingLang) {
    const objectMetaData = get(META_DATA, `${region || 'sg'}.${metaKey}`);
    const firstLangKey = Object.keys(objectMetaData || {})?.[0] || 'en';

    meta = get(objectMetaData, `${currentLang}`, `${objectMetaData}.${firstLangKey}`) as MetaItemProps;
  }

  const headMetaData = useMemo(() => {
    if (metaData) {
      return {
        ...meta,
        title: metaData.title,
        metaTitle: metaData.metaTitle,
        description: metaData.description,
      };
    }

    return { ...meta };
  }, [metaData, meta]);

  const metaTitle = headMetaData?.metaTitle || headMetaData?.title || '';

  const seoSchema = {
    '@context': 'http://schema.org',
    '@id': 'https://carro.co/#webpage',
    '@type': 'WebPage',
    url: canonicalUrl,
    name: metaTitle,
    description: headMetaData?.description,
  };

  const alternateLinks = getAlterLinks(region, asPath)?.map(({ hrefLang, href }) => (
    <link rel="alternate" hrefLang={hrefLang} href={href} />
  ));

  return (
    <Head>
      <title>{headMetaData?.title}</title>

      <meta property="og:image" content={headMetaData?.image} />
      <meta property="og:image:secure_url" content={headMetaData?.image} />
      <meta property="og:image:width" content={headMetaData?.width} />
      <meta property="og:image:height" content={headMetaData?.height} />

      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={headMetaData?.description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Carro" />

      <meta name="title" content={metaTitle} />
      <meta name="description" content={headMetaData?.description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={headMetaData?.description} />
      <meta name="twitter:image:src" content={headMetaData?.image} />

      {alternateLinks}

      <link rel="canonical" href={canonicalUrl} />
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(seoSchema) }} />
    </Head>
  );
};

export default HeadMeta;
